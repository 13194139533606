/**
 *   Callback button
 */

module.exports = function () {

    return function (app) {
        callbackButton()
    }
  
    function callbackButton() {

      if (jQuery('body:lang(de-DE) .popup-wrap').length > 0) {
        jQuery('.popup-wrap').prepend('<div class="callback-service"><a href="https://www.rechner-sensors.com/kontakt#callback" target="_blank" rel="noopener noreferrer"><i class="fas fa-phone-volume"></i></a></div>');
      }
    }
}
  