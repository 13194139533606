/**
 *   Toggle button
 */

module.exports = function () {

    return function (app) {
        search()
    }
  
    function search() {
        var el = document.getElementById('searchtrigger');
        el.addEventListener("click", function () {
          console.log('clicked')
          var nav = this.parentElement.parentElement,
            _class = "is-active",
            _content = "search"
    
          el.classList.contains(_class)
            ? el.classList.remove(_class)
            : el.classList.add(_class);
          
          nav.classList.contains(_content)
            ? nav.classList.remove(_content)
            : nav.classList.add(_content);

          // Focus search input
          setTimeout(function() {
            document.querySelector('.o-header .aws-search-field').focus();
          }, 500);
        });
    }
}
  