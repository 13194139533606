'use strict'

/**
 * Module dependencies.
 */

// Vendor
var jQuery = window.$ = window.jQuery// = require('jquery')
// var Swiper = window.Swiper = require('swiper').default
// var iCheck = require('./vendors/icheck')

// Libraries
var toggleButton = require('./lib/toggle-button')
var search = require('./lib/search')
var callbackButton = require('./lib/callback-button')
var selectHooks = require('./lib/select2')
var scrollParallax = require('./lib/parallax')
// var cardResult = require('./lib/card-result')
var vcAccordionCloseLogic = require('./lib/vc-accordion-logic')

/**
 * Initialize a new App instannce.
 */

function App () {
  if (!(this instanceof App)) return new App()

  // init plugins

  // init actions
  $(document).ready( () => {
    this.use(toggleButton())
    this.use(search())
    this.use(callbackButton())
    this.use(selectHooks())
    this.use(scrollParallax())
    // this.use(cardResult())
    this.use(vcAccordionCloseLogic())
  });
}

/**
 * Use the given plugin `fn`.
 *
 * @param {Function} fn
 * @return {App} self
 * @api public
 */

App.prototype.use = function (fn) {
  fn(this)
  return this
}

// run the app
App()
