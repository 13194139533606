/**
 *   VC accordion close
 */

 module.exports = function () {

    return function (app) {
        vcAccordionCloseLogic()
    }
  
    function vcAccordionCloseLogic() {
        // Extend visual composer accordion functionality
        if (jQuery('.vc_tta').length > 0) {
            jQuery('.vc_tta .vc_tta-panel').click(function() {
                if (jQuery(this).hasClass('vc_active')) {
                    // Close all accordions again
                    setTimeout(function(){
                        var wWidth = window.innerWidth;
                        if( wWidth < 768 && jQuery(this).parents('.vc_tta-tabs').length) {
                            jQuery(this).removeClass('vc_active');
                            // Remove hash from url
                            var noHashURL = window.location.href.replace(/#.*$/, '');
                            window.history.replaceState('', document.title, noHashURL); 				
                        } else {
                            //jQuery(this).parents('.vc_tta').find('.vc_tta-panel').removeClass('vc_active');
                            jQuery('.vc_tta-panel').removeClass('vc_active');
                            // Remove hash from url
                            var noHashURL = window.location.href.replace(/#.*$/, '');
                            window.history.replaceState('', document.title, noHashURL); 
                        }
                    }, 0);
                }
            });
        }
    }
}