/**
 *   Callback button
 */

module.exports = function () {

    return function (app) {
      selectHooks()
    }
  
    // function $_GET(param) {
    //   var vars = {};
    //   window.location.href.replace( location.hash, '' ).replace( 
    //     /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    //     function( m, key, value ) { // callback
    //       vars[key] = value !== undefined ? value : '';
    //     }
    //   );
  
    //   if ( param ) {
    //     return vars[param] ? vars[param] : null;	
    //   }
    //   return vars;
    // }

    function selectHooks() {
          // iCheck style checkbox and radio button with style square black
          /*jQuery('input').iCheck({
            checkboxClass: 'icheckbox_square',
            radioClass: 'iradio_square',
            increaseArea: '20%' // optional
          });*/
        
          jQuery('.dropdown_product_cat').select2({
            width: '100%'
          });
        
          // Hide first stock output
          jQuery('.stock_wrapper .in-stock').show();
        
          jQuery('.skulist_DE, .skutotallist_DE').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Diese Artikelnummer existiert nicht.";
                }
              }
          });
        
          jQuery('.skulist_EN, .skutotallist_EN').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Article number not found.";
                }
              }
          });
        
          jQuery('.skulist_ES, .skutotallist_ES').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Este número de artículo no existe.";
                }
              }
          });
        
          jQuery('.skulist_IT, .skutotallist_IT').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Questo numero di articolo non esiste.";
                }
              }
          });
        
          jQuery('.skulist_FR, .skutotallist_FR').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Ce numéro d'article n'existe pas.";
                }
              }
          });

          jQuery('.country_DE').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Dieses Land existiert nicht.";
                }
              }
          });

          jQuery('.country_EN').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "This country doesn't exist.";
                }
              }
          });

          jQuery('.country_FR').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Ce pays n'existe pas.";
                }
              }
          });

          jQuery('.country_ES').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Este país no existe.";
                }
              }
          });

          jQuery('.country_IT').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Questo paese non esiste.";
                }
              }
          });

          jQuery('.preisliste_de').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Diese Artikelnummer existiert nicht.";
                }
              }
          });

          jQuery('.projectrequest_de').select2({
            width: '100%',
              language: {
                noResults: function (params) {
                  return "Diese Artikelnummer existiert nicht.";
                }
              }
          });

          function removeString( params, data) {  
            return params.Substring(0, params.IndexOf("|") + 1);
          }
        
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);

          // Preselect chosen value
          if (urlParams.get('sku')) {
            let sku = urlParams.get('sku');
            let optionFound = jQuery('.wpcf7-select option:contains('+sku+')').val();
            // console.log(optionFound);
            jQuery('.wpcf7-select').val(optionFound);
            /*jQuery('.wpcf7-select').trigger("chosen:updated");*/
            jQuery('.wpcf7-select').trigger("change");
          }
    }
}
  